/* Navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.navbarLogo {
    font-size: 1.5rem;
    font-weight: 700;
    color: #143e16;
}

.navbarMenu {
    list-style: none;
    display: flex;
    gap: 2rem;
    margin-right: 5rem;
}

.navbarMenu li a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s ease;
}

.navbarMenu li a:hover {
    color: #4caf50;
}

/* Conteúdo principal */
.homeContainer {
    padding-top: 4rem; /* Espaço para a navbar fixa */
}

.mainContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    text-align: center;
}

/* Seção de introdução */
.introSection {
    margin-top: 2rem;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.introTitle {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #333;
}

.introText {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 2rem;
    color: #666;
}

.ctaButton {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 0.8rem 2rem;
    font-size: 1.1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    display: inline-block; /* Garante que o link se comporte como um botão */
}

.ctaButton:hover {
    background-color: #4caf50;
    transform: scale(1.05); /* Ajuste a escala como necessário */
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

/* Seção de recursos */
.featuresSection {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 1rem;
    max-width: 1000px;
    margin: 3rem auto;
    scroll-margin-top: 100px; /* Adiciona uma margem para a rolagem */
}

.featureCard {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.3s ease;
}

.featureCard:hover {
    transform: translateY(-5px);
}

.featureIcon {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

/* Estilo da Seção Sobre */
.aboutSection {
    padding: 4rem 1rem;
    max-width: 900px;
    margin: 3rem auto;
    text-align: center;
}

.aboutTitle {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #333;
}

.aboutDescription {
    font-size: 1.2rem;
    font-weight: 300;
    color: #666;
    margin-bottom: 2rem;
    max-width: 700px;
    margin: 0 auto;
}

.aboutCards {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 2rem;
    align-items: stretch; /* Garante que todos os cards tenham a mesma altura */
}

.aboutCard {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    flex: 1; /* Permite que cada card tenha o mesmo tamanho flexível */
    text-align: center;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column; /* Faz com que o conteúdo dentro do card se alinhe em coluna */
    justify-content: space-between; /* Garante que o conteúdo esteja bem distribuído verticalmente */
}

.aboutCard h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #333; /* Cor de destaque */
}

.aboutCard p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
    flex-grow: 1; /* Faz com que o texto expanda para ocupar o espaço disponível */
}

.aboutCard:hover {
    transform: translateY(-5px);
}

/* Footer */
.footer {
    background-color: #ffffff; /* Fundo branco para combinar com o header */
    color: #333; /* Cor de texto principal */
    padding: 1.5rem 2rem;
    text-align: center;
    width: 100%;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave para destacar o footer */
    position: relative;
    bottom: 0;
    left: 0;
}

.footer p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
}

.footer a {
    color: #4caf50; /* Cor principal do site para os links */
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.footer a:hover {
    color: #66bb6a; /* Cor mais clara no hover */
}