.mainPage{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.image{
    width: 60%;
    height: 100vh;
}

.image img{
    width: 100%;
    height: 100%;
    filter: blur(1px)
}

.formulario {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100vh;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5); /* Sombra maior e mais forte */
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    background-color: #fff;
}

.formulario form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px 0px;
    width: 100%;
}

.formulario h1 {
    font-size: 2rem;
    margin-bottom: 5vh;
    color: #111;
    text-align: center;
}

.formulario form label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}


.formulario form div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;    
    width: 50%;
    margin-bottom: 20px;
    align-items: center;
}

.hiddenCheckbox {
    display: none; /* Esconde o checkbox */
}

.checkboxWrapper {
    position: relative;
    display: block; /* Para alinhar corretamente os elementos */
}

.hiddenCheckbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkboxLabel {
    display: inline-block;
    padding: 5px 20px;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-size: 0.9rem;
    transition: border-color 0.3s, box-shadow 0.3s;
    width: auto;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
    cursor: pointer;
    text-align: center;
    user-select: none;
    margin: 0 5px 5px 5px;
    transition: .2s;
}

.checkboxLabel:hover {
    border-color: #00070f;
    box-shadow: 0 0 0 2px rgba(0, 15, 30, 0.1);
}

.hiddenCheckbox:checked + .checkboxLabel {
    background-color: rgb(230, 230, 230);
    color: #000;
    border-color: #00070f;
}


.formulario button {
    margin-left: 20px;
    padding: 15px;
    border: none;
    border-radius: 8px;
    background: #007BFF;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.5s, transform 0.2s;
    width: calc(50% + 20px);
    user-select: none;
}

.formulario button:hover {
    background: #0056b3;
    transform: scale(1.05);
}

.formulario button:active {
    transform: translateY(1px);
}