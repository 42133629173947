.mainPage{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.formulario {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100vh;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5); /* Sombra maior e mais forte */
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    background-color: #fff;
}

.formulario h1 {
    font-size: 2rem;
    margin-bottom: 5vh;
    color: #111;
    text-align: center;
}

.formulario form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px 0px;
    width: 100%;
}

.formulario form div {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.ultimoCampo{
    margin-bottom: 20px;
}

.formulario form label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

.formulario form input {
    padding: 10px;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s, box-shadow 0.3s;
    width: 100%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.formulario form input:focus {
    border-color: #007BFF;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    outline: none;
}

.formulario button {
    margin-left: 20px;
    padding: 15px;
    border: none;
    border-radius: 8px;
    background: #007BFF;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.5s, transform 0.2s;
    width: calc(50% + 20px);
    user-select: none;
}

.formulario button:hover {
    background: #0056b3;
    transform: scale(1.05);
}

.formulario button:active {
    transform: translateY(1px);
}

.image{
    width: 60%;
    height: 100vh;
}

.image img{
    width: 100%;
    height: 100%;
    filter: blur(1px)
}

.asideBack{
    position: fixed;
    top: 0;
    left: 0;
}

.asideBack a{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    background-color: rgb(144, 0, 0);
    border-bottom-right-radius: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    text-decoration: none;
    color: #f7f7f7;
    font-size: 1rem;
    transition: background-color 0.3s, padding 0.3s;
}

.asideBack a:hover{
    background-color: rgb(122, 0, 0);
    padding: 1.2rem 2.2rem;
}

.ativarConta{
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    text-decoration: none;
    color: #222;
    font-size: 1rem;
    transition: background-color 0.3s, padding 0.3s;
}