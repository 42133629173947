/* Estilo global */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  background-color: #f0f2f5;
  color: #333;
  overflow-x: hidden;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}